export const THEME = {
    colors: {
        rectangle: '#1711c2',
        rectangle_lighter: '#1f42ff',
        background: '#222222',
        logo_bg_dark: '#333333',
        logo_bg_light: '#ececec',
        white: '#ffffff',
        white_02: 'rgba(255, 255, 255, 0.2)',
        czynszomat_bg: '#FEDD98',
        kancelaria_bg: '#203343',
        new_balance_bg: '#174339',
    },
};
