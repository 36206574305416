import { createGlobalStyle } from 'styled-components';
import '@fontsource/italiana';
import '@fontsource/lato/100.css';

export const GlobalStyle = createGlobalStyle`
  *, *:before, *:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: ${({ theme }) => theme.colors.white};
    font-family: 'Lato', sans-serif;
    letter-spacing: 0.04375rem;
  }
  
  body {
    background-color: ${({ theme }) => theme.colors.background};
    scroll-behavior: smooth;
  }
`;
