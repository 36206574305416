import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Layout } from 'layouts/Layout';
import { THEME } from 'theme';
import { GlobalStyle } from 'theme/globalStyles';

export const wrapPageElement = ({ element, props }) => {
    return (
        <ThemeProvider theme={THEME}>
            <GlobalStyle />
            <Layout {...props}>{element}</Layout>
        </ThemeProvider>
    );
};

export const onClientEntry = async () => {
    if (!('scrollBehavior' in document.documentElement.style)) {
        await import('scroll-behavior-polyfill');
    }
};
