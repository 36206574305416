// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-alvesco-js": () => import("./../../../src/pages/alvesco.js" /* webpackChunkName: "component---src-pages-alvesco-js" */),
  "component---src-pages-ciech-js": () => import("./../../../src/pages/ciech.js" /* webpackChunkName: "component---src-pages-ciech-js" */),
  "component---src-pages-czynszomat-js": () => import("./../../../src/pages/czynszomat.js" /* webpackChunkName: "component---src-pages-czynszomat-js" */),
  "component---src-pages-fashion-js": () => import("./../../../src/pages/fashion.js" /* webpackChunkName: "component---src-pages-fashion-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-italux-js": () => import("./../../../src/pages/italux.js" /* webpackChunkName: "component---src-pages-italux-js" */),
  "component---src-pages-itseptic-js": () => import("./../../../src/pages/itseptic.js" /* webpackChunkName: "component---src-pages-itseptic-js" */),
  "component---src-pages-kurowski-brand-js": () => import("./../../../src/pages/kurowski-brand.js" /* webpackChunkName: "component---src-pages-kurowski-brand-js" */),
  "component---src-pages-kurowski-page-js": () => import("./../../../src/pages/kurowski-page.js" /* webpackChunkName: "component---src-pages-kurowski-page-js" */),
  "component---src-pages-leclerc-leroy-js": () => import("./../../../src/pages/leclerc-leroy.js" /* webpackChunkName: "component---src-pages-leclerc-leroy-js" */),
  "component---src-pages-logofolio-js": () => import("./../../../src/pages/logofolio.js" /* webpackChunkName: "component---src-pages-logofolio-js" */),
  "component---src-pages-new-balance-js": () => import("./../../../src/pages/new-balance.js" /* webpackChunkName: "component---src-pages-new-balance-js" */),
  "component---src-pages-sarmin-js": () => import("./../../../src/pages/sarmin.js" /* webpackChunkName: "component---src-pages-sarmin-js" */),
  "component---src-pages-schneider-js": () => import("./../../../src/pages/schneider.js" /* webpackChunkName: "component---src-pages-schneider-js" */),
  "component---src-pages-tikkurila-js": () => import("./../../../src/pages/tikkurila.js" /* webpackChunkName: "component---src-pages-tikkurila-js" */),
  "component---src-pages-ziemowit-js": () => import("./../../../src/pages/ziemowit.js" /* webpackChunkName: "component---src-pages-ziemowit-js" */)
}

