import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

const SLayout = styled.div`
    height: 100vh;
    width: 100vw;
    transform: translate3d(0, 0, 0);
`;

export const Layout = ({ children }) => {
    return (
        <>
            <Helmet
                title={'Mateusz Kurowski Graphic Designer'}
                meta={[
                    {
                        name: 'description',
                        content: '',
                    },
                    { name: 'viewport', content: 'width=device-width, initial-scale=1, maximum-scale=1' },
                    {
                        name: 'keywords',
                        content: `Mateusz Kurowski designer art design webdesigner portfolio product identity logo
                            graphic design`,
                    },
                    { name: 'msapplication-TileColor', content: '#1711c2' },
                    { name: 'theme-color', content: '#222222' },
                    { property: 'og:title', content: 'Mateusz Kurowski' },
                    { property: 'og:description', content: '_designer art' },
                ]}
                link={[
                    { rel: 'apple-touch-icon', sizes: '180x180', href: '/apple-touch-icon.png' },
                    { rel: 'icon', type: 'image/png', sizes: '32x32', href: '/favicon-32x32.png' },
                    { rel: 'icon', type: 'image/png', sizes: '16x16', href: '/favicon-16x16.png' },
                    { rel: 'manifest', href: '/site.webmanifest' },
                    { rel: 'mask-icon', href: '/safari-pinned-tab.svg', color: '#1711c2' },
                ]}
            />
            <SLayout>{children}</SLayout>
        </>
    );
};
